<!--  -->
<template>
    <div>
        <div class="sport-item" v-if="environmentalType == 3" @click="doParentFunctionForToggle" v-bind:class="{'item-active': v_is_active}">
            <div class="img">
                <img :src="img" alt="" />
                <p>{{ title }}</p>
            </div>
            <div class="amount">
                <p>场地数量<cite>（个）</cite></p>
                <h3>{{ v_list.length || '0' }}</h3>
            </div>
            <div class="area">
                <p>场地面积<cite>（㎡）</cite></p>
                <h3>{{ placeArea ? placeArea : 0 }}</h3>
            </div>
        </div>
        <div class="health-item item_bg" v-else-if="environmentalType == 4" @click="doParentFunctionForToggle" v-bind:class="{'item-active': v_is_active}">
            <div class="img">
                <img
                    :src="img"
                    alt=""
                    style="
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        -o-object-fit: cover;
                        vertical-align: top;
                    "
                />
            </div>
            <div class="content">
                <div class="title">
                    {{ title }}
                </div>
                <img
                    class="button"
                    :src="require('@/assets/img/server/detailed.png')"
                    alt=""
                />
                <!-- <p>{{ detailContent }}</p> -->
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="detailContent"
                    placement="right-end"
                    popper-class="tooltip-width"
                >
                    <p>{{ detailContent }}</p>
                </el-tooltip>
            </div>
        </div>
        <div class="other-item item_bg" v-else-if="environmentalType == 5" @click="doParentFunctionForToggle" v-bind:class="{'item-active': v_is_active}">
            <div class="img">
                <img :src="img" alt="" />
            </div>
            <div class="content">
                <div class="title col_f">
                    {{ title }}
                </div>
                <img
                    class="button"
                    :src="require('@/assets/img/server/detailed.png')"
                    alt=""
                />
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="detailContent"
                    placement="left-end"
                    popper-class="tooltip-width"
                >
                    <p>{{ detailContent }}</p>
                </el-tooltip>
            </div>
        </div>
        <div v-else class="life-item" @click="doParentFunctionForToggle" v-bind:class="{'item-active': v_is_active}">
            <div class="img">
                <img
                    :src="img"
                    alt=""
                    style="
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        -o-object-fit: cover;
                        vertical-align: top;
                    "
                />
            </div>
            <div class="content">
                <div class="title">
                    {{ title }}
                </div>
                <p>{{ v_list.length || '0' }} <cite>(所)</cite></p>
            </div>
        </div>
        <!-- <div v-for="(item, index) in v_zu_list" :key="index" v-show="v_show_zu">
            <dot-pop
                :name="item.mc"
                :x="item.cx"
                :y="item.cy"
                :z="7"
                type="dot"
                :color="v_dot_color"
            >
                <img style="width: 0.3rem" :src="markerUrl" />
            </dot-pop>
        </div> -->


    </div>
</template>

<script>
import { getDiShiZhouCodeByCunCode, getEnvelopeByList } from '@/util/common.js';
import { getLonLatArray, addPoint, addPolygonFunction, addLine, cleanMarkerListByType, getMakerById, drawCircle } from '@/util/drawShape.js';
import http from '@/util/http.js';
import dotPop from "@/components/dot-pop.vue"
import { mapState} from 'vuex';

var g_list_overlay = [];
var g_bln_height_up = '';//判断是否超过500，超过为true否则false
var g_zu_marker_list_overlay = [];
var g_HEIGHT_CHANG_CAMERA = 500;
export default {
    components: { dotPop },
    props: {
        title: String,
        layerName: String,
        type: String,
        queryParameter: String,
        img: String,
        typeInfo: String,
        environmentalType: Number,
        dotPopActive: -1,
        layerType: { type: String, default: 'd' },//d x m  点线面
        fieldName: { type: String, default: 'mc' },
        markerUrl: { type: String, default: require('@/assets/marker/location.png') },
        width: { type: Number, default: 30 },
        height: { type: Number, default: 36 },
        ifshow: { type: Boolean, default: false },
        placeArea: { type: Number, default: 0 },
        detailContent: { type: String, default: '' },
    },
    data() {
        return {
            v_list: [],
            v_show: false,
            g_xqid: localStorage.getItem('tenantCode'),
            g_dsz_id: getDiShiZhouCodeByCunCode(localStorage.getItem('tenantCode')),
            v_dot_color: '#00c996',
            v_if_show_img: false,
            v_item: {mc:'', img: '', x: 0, y: 0},
            v_bln_show_marker: false,
            v_marker_data: [],//自定义的标注数据集合
            // v_show_zu: false,
            v_is_active: false,
            // v_zu_list: []//村小组
        }
    },
    watch: {
        ifshow: function (newVal, oldVal) {
            if (newVal) {
                this.show();
            } else {
                this.hide();
            }
        }
    },
    computed: {
        ...mapState(['menu_active'])
    },
    methods: {
        init() {
            this.queryLayer();//console.log('init--title--environmentalType', this.title, this.environmentalType);
        },
        doParentFunctionForToggle(){
            this.$emit("toggleOverlay");
        },

        // showImg(item){
        //      if(this.environmentalType != 4 && this.environmentalType != 5){
        //          this.v_if_show_img = false;
        //          return;
        //      }
        //      if(this.img){
        //         if(this.v_if_show_img){
        //             this.v_if_show_img = false;
        //         }else{
        //             this.v_item.x = item.x;
        //             this.v_item.y = item.y;
        //             this.v_item.mc = item.name;
        //             this.v_item.img = this.img;//默认的图片
        //             if(item.img){
        //                 this.v_item.img = item.img;
        //             }
        //             this.v_if_show_img = true;
        //         }
        //      }
        // },
        // onCloseImg() {
        //     this.v_if_show_img = false;
        // },

        queryLayer() {
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "maxFeatures": 3000,
                "datasetNames": ["p" + this.g_dsz_id + ":" + this.layerName],
                "queryParameter": this.queryParameter ? {
                    "attributeFilter": "SSCID= '" + this.g_xqid + "' " + 'and ' + this.queryParameter,
                    "fields": ["smid", "mc"]
                } : {
                    "attributeFilter": "SSCID= '" + this.g_xqid + "' ",
                    "fields": ["smid", "mc"]//, "sslb", "sscmc", "cjsj"
                }
            }
            //图片特殊处理
            if(this.layerName == 't03_fw_zs' || this.layerName == 't03_fw_cy'){
                _sqlParameter.queryParameter.fields.push('img');
            }
            let x_y = [];
            http.requestIServerWithPage(_sqlParameter, 0, 2000, (result) => {
                if (this.type == 'addPoint') {
                    if (result.features.length > 0) {
                        this.v_list = [];
                        for (var i = 0; i < result.features.length; i++) {
                            var _feature = result.features[i];
                            var _item = {
                                id: i,
                                smid: _feature.fieldValues[0],
                                name: _feature.fieldValues[1],
                                img: this.img,//
                                environmentalType: this.environmentalType,//
                                title: this.title,//
                                markerUrl: this.markerUrl,//
                                x: _feature.geometry.center.x,
                                y: _feature.geometry.center.y,
                                shape: [_feature.geometry.center.x, _feature.geometry.center.y]
                            };
                            if(this.layerName == 't03_fw_zs' || this.layerName == 't03_fw_cy'){//图片特殊处理
                                _item.img = _feature.fieldValues[2]||this.img;
                            }
                            this.v_list.push(_item);
                        }
                    }
                } else if (this.type == 'addLine') {
                    if (result.features.length > 0) {
                        for (var j = 0; j < result.features.length; j++) {
                            var dlx_point_jh = result.features[j].geometry.points;
                            x_y = [];
                            for (var i = 0; i < dlx_point_jh.length; i++) {
                                var every_point = dlx_point_jh[i];
                                x_y.push(every_point.x);
                                x_y.push(every_point.y);
                            }
                            this.v_list.push({
                                type: 1,
                                id: j + 1,
                                mc: result.features[j].fieldValues[0],
                                shape: x_y
                            });
                        }
                    }
                } else if (this.type == 'addPolygonFunction') {
                    if (result.features.length > 0) {
                        for (var i = 0; i < result.features.length; i++) {
                            var feature = result.features[i];
                            var dlx_point_jh = result.features[i].geometry.points;
                            x_y = [];      //储存面图形的所有点
                            for (var j = 0; j < dlx_point_jh.length; j++) {
                                var every_point = dlx_point_jh[j];
                                x_y.push(every_point.x);
                                x_y.push(every_point.y);
                            }
                            this.v_list.push({
                                id: feature.fieldValues[0],
                                shape: x_y,
                                type: 2,
                                mc: feature.fieldValues[1],
                                szxq: feature.fieldValues[2],
                                cjsj: feature.fieldValues[3]
                            });
                        }
                    }

                } else if (this.type == 'drawCircle') {
                    for (var i = 0; i < result.features.length; i++) {
                        var feature = result.features[i];
                        var fsbj = 2000;
                        if (feature.fieldValues[2]) {
                            fsbj = parseFloat(feature.fieldValues[2]);
                        }
                        var item = {
                            id: feature.fieldValues[0],
                            mc: feature.fieldValues[1],
                            fsbj: fsbj, //feature.fieldValues[2],
                            type: 'jz',
                            x: feature.geometry.center.x,
                            y: feature.geometry.center.y,
                            isShowCenterPoint: true,
                            isShowCircle: true
                        }
                        this.v_list.push(item);
                    }
                }

            });
        },
        hide() {
            // cleanMarkerListByType('layer');
            // viewer.entities.removeAll()
            g_list_overlay = [];
            // if (this.layerName == 't04_hbss_ljt') {//对垃圾桶进行特别处理
            //     viewer.camera.changed.removeEventListener(this.listenHeight);
            //     this.v_show_zu = false;
            // }
            this.$bus.$emit('busEventHideMarker');
            // this.v_marker_data = [];
            // this.v_bln_show_marker = false;
            // this.v_if_show_img = false;
            this.v_is_active = false;//突显背景色
        },
        show() {
            // cleanMarkerListByType('layer');
            if (this.type == 'addPoint') {
                // var _config = {};
                // _config.image = this.markerUrl;
                // _config.width = this.width;
                // _config.height = this.height;

                // for (var i = 0; i < this.v_list.length; i++) {
                //     var _item = this.v_list[i];
                //     g_list_overlay.push(addPoint('layer', _item.id, '', _item.shape, '', _config));
                // }
                this.$bus.$emit('busEventShowMarker', this.v_list, this.markerUrl);
                // this.v_marker_data = this.v_list;
                // this.v_bln_show_marker = true;
                //定位到合适的地图范围
                var _envelope = getEnvelopeByList(this.v_list);
                if (_envelope.length > 3) {
                    viewer.camera.flyTo({
                        destination: Cesium.Rectangle.fromDegrees(
                            _envelope[0],
                            _envelope[1],
                            _envelope[2],
                            _envelope[3]
                        ) //west, south, east, north)
                    });
                }

            } else if (this.type == 'addLine') {
                for (var i = 0; i < this.v_list.length; i++) {
                    var item = this.v_list[i];
                    if (this.layerName == "t04_xcdl") {
                        g_list_overlay.push(addLine('layer', item.id, '', item.shape, null, { lineColor: '#0084ff' }));
                    } else {
                        g_list_overlay.push(addLine('layer', item.id, '', item.shape, null, { lineColor: '#ffc748' }));
                    }
                }
            } else if (this.type == 'addPolygonFunction') {
                for (var i = 0; i < this.v_list.length; i++) {
                    var item = this.v_list[i];
                    var _config = { isFill: true };
                    if (this.layerName == "t02_slss_sk") {
                        _config.polygonFillColor = '#11abff';
                        _config.alpha = 0.7;
                    }
                    g_list_overlay.push(addPolygonFunction('layer', item.id, '', item.shape, null, _config));
                }
            } else if (this.type == 'drawCircle') {
                var _config = {};
                _config.image = this.markerUrl;
                _config.width = this.width;
                _config.height = this.height;
                for (var i = 0; i < this.v_list.length; i++) {
                    var item = this.v_list[i];
                    // g_list_overlay.push(drawCircle(this.layerName, item.x, item.y, item.fsbj));
                    g_list_overlay.push(addPoint('layer', item.id, '', [item.x, item.y], '', _config));
                }
            }
            if (this.type != 'addPoint'){//除点，其他采用此方式定位
                var heading = Cesium.Math.toRadians(0.0);
                var pitch = Cesium.Math.toRadians(-90.0);
                viewer.flyTo(g_list_overlay, {
                    //duration: 5,
                    offset: new Cesium.HeadingPitchRange(heading, pitch)
                });
            }
            this.v_is_active = true;
        }

    },
    created() { },
    mounted() {
        let pMountedTimer = window.setInterval(() => {
            if (window.parentMounted) {
                window.clearInterval(pMountedTimer)
                this.init();
            }
        }, 500)
    },
    destroyed() {
        cleanMarkerListByType('layer');
        cleanMarkerListByType('zu_marker_layer');
        // this.v_show_zu = false;
        // this.v_zu_list = [];
        g_list_overlay = [];
        // this.v_marker_data = [];
        // this.v_bln_show_marker = false;
        // this.v_if_show_img = false;
    }
}

</script>
<style lang="scss">
.tooltip-width {
    max-width: 3rem;
}
</style>
<style lang='scss' scoped>
.item-active{
    background-color: #09fafa50;
}
.sport-item {
    &:hover {
        background-color: #09fafa20;
    }
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-shrink: 0;
    width: 94%;
    padding: 0.08rem;
    height: 0.8rem;
    // background: rgba(2, 22, 51, 0.3);
    border: 0.02rem solid rgba(51, 255, 238, 0.16);
    margin-bottom: 0.08rem;
    height: 0.7rem;
    cursor: pointer;
    .img {
        width: 0.8rem;
        text-align: center;
        img {
            width: 0.48rem;
            height: 0.48rem;
            object-fit: cover;
            -o-object-fit: cover;
        }
        p {
            font-size: 0.12rem;
            color: #ffffff;
            line-height: 0.14rem;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 0.06rem;
                width: 100%;
                background: linear-gradient(
                    180deg,
                    rgba(12, 101, 234, 0.38) 0%,
                    rgba(51, 255, 238, 0.7) 100%
                );
            }
        }
    }
    div.amount,
    .area {
        padding: 0.15rem 0;
    }
    div > p {
        font-size: 0.12rem;
        color: rgba(225, 242, 245, 0.8);
        line-height: 16px;
        margin-bottom: 0.08rem;
        cite {
            font-style: normal;
        }
    }
    div > h3 {
        font-size: 0.2rem;
        font-weight: bold;
        color: #ffffff;
        line-height: 0.24rem;
    }
}
.life-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 0.08rem;
    height: 0.52rem;
    // background: rgba(3, 32, 77, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 0.01rem solid;
    padding: 0.04rem;
    box-sizing: border-box;
    cursor: pointer;
    border-image: linear-gradient(
            90deg,
            rgba(24, 142, 245, 1),
            rgba(24, 142, 245, 0)
        )
        1 1;
    .img {
        height: 0.4rem;
        width: 0.4rem;
    }
    .content {
        margin-left: 0.08rem;
        .title {
            height: 0.2rem;
            background: linear-gradient(
                90deg,
                rgba(24, 142, 245, 0.3) 0%,
                rgba(24, 142, 245, 0) 100%
            );
            font-size: 0.14rem;
            color: #ffffff;
            line-height: 0.2rem;
            padding-left: 0.08rem;
            margin-top: 0;
            padding-top: 0.02rem;
            &::before {
                display: none;
            }
        }
        p {
            text-align: center;
            color: #19f5f5;
            font-size: 0.18rem;
            cite {
                font-size: 0.1rem;
                color: rgba(255, 255, 255, 0.6);
                float: right;
                margin-right: 0.35rem;
                font-style: normal;
                margin-top: 0.03rem;
            }
        }
    }
}
.title {
    height: 0.2rem;
    font-size: 0.14rem;
    font-weight: 500;
    color: #00ffff;
    line-height: 0.2rem;
    padding-left: 0.2rem;
    position: relative;
    margin-top: 0.08rem;
    &::before {
        content: '';
        width: 0.06rem;
        height: 0.06rem;
        background: #18f5f5;
        position: absolute;
        left: 0.03rem;
        top: 0.08rem;
    }
    &.col_f {
        color: #fff;
    }
}
.item_bg > .img {
    &::before {
        content: '';
        height: 100%;
        width: 0.04rem;
        background: url('~@/assets/img/server/img-bg-left.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    &::after {
        content: '';
        height: 100%;
        width: 0.04rem;
        background: url('~@/assets/img/server/img-bg-right.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        right: 0;
    }
}
.health-item {
    cursor: pointer;
    &:hover {
        background-color: #09fafa20;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 0.08rem;

    .img {
        height: 0.73rem;
        width: 0.88rem;
        padding: 0.04rem;
        position: relative;
    }
    .content {
        // position: relative;
        margin-left: 0.08rem;
        .title {
            margin-top: 0;
        }
        .button {
            position: absolute;
            top: 0;
            right: 0;
            width: 0.5rem;
            height: 0.18rem;
            cursor: pointer;
        }
        p {
            font-size: 0.12rem;
            line-height: 0.18rem;
            color: #ebeff5;
            margin-top: 0.04rem;
            line-height: 0.18rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; // 想要超出三行显示 就把这里改成3就好了
        }
    }
}
.other-item {
    cursor: pointer;
    &:hover {
        background-color: #09fafa20;
    }
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.08rem;
    .img {
        height: 0.55rem;
        width: 0.55rem;
        margin-right: 0.08rem;
        padding: 0.03rem;
        position: relative;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            -o-object-fit: cover;
        }
    }
    .content {
        position: relative;
        .title {
            margin-top: 0;
        }
        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 0.5rem;
            height: 0.18rem;
            cursor: pointer;
        }
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; // 想要超出三行显示 就把这里改成3就好了
        }
    }
}
.cls-pop-pane {
    font-size: 16px;
    height: 320px;
}
</style>