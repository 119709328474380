<!-- 服务图 -->
<template>
    <div>
        <div class="page_temp page_temp_left">
            <div class="machinery" style="height: 4.3rem; position: relative">
                <div>
                    <item-title
                        :name="'农机服务'"
                        english="Agricultural machinery service"
                    />
                    <div v-if="v_nj_list_state">
                        <line-border-box type="bottom" />
                        <!-- 柱状图 -->
                        <div class="title" style="margin-top: 0.04rem">
                            农机统计
                        </div>
                        <div class="farmCharts">
                            <span>数量（台）</span>
                            <div id="farmCharts"></div>
                        </div>
                        <!-- <div class="line_between">
                        <img src="../assets/new_img/line_bg.png" alt="" />
                    </div> -->
                        <!-- 旋耕服务、收割服务 -->
                        <div class="title" style="margin-top: 0.04rem">
                            农机信息
                        </div>
                        <div class="cz">
                            <div
                                style="
                                    padding: 0 0.15rem;
                                    box-sizing: border-box;
                                    width: 100%;
                                "
                            >
                                <div
                                    class="cz_list"
                                    style="
                                        background-color: rgba(6, 65, 153, 0.6);
                                        color: rgba(255, 255, 255, 0.6);
                                        margin-top: 0.08rem;
                                    "
                                >
                                    <span>农机服务</span>
                                    <span>数量</span>
                                    <span>联系人</span>
                                    <span>联系方式</span>
                                </div>
                            </div>
                            <div class="cz_list_box">
                                <div
                                    v-for="(item, index) in v_nj_list"
                                    class="cz_list"
                                    :class="
                                        nj_list_active == index
                                            ? 'cz_list_active'
                                            : ''
                                    "
                                    :key="index"
                                    @click="
                                        ;(nj_list_active = index),
                                            facilityClick(item.type)
                                    "
                                >
                                    <span>{{ item.type }}</span>
                                    <span>{{ item.num ||'--'}}</span>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="item.name"
                                        placement="bottom-start"
                                    >
                                        <span
                                            style="
                                                display: -webkit-box;
                                                -webkit-line-clamp: 1;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                -webkit-box-orient: vertical;
                                                text-align: center;
                                            "
                                            >{{ item.name ||'--'}}</span
                                        >
                                    </el-tooltip>
                                    <span>{{ item.phone ||'--'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            style="
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 3rem;
                                flex-direction: column;
                            "
                        >
                            <img
                                style="width: 100%"
                                src="../assets/img/nodata.png"
                                alt=""
                            />
                            <!-- <span
                                style="
                                    color: #fff;
                                    font-size: 0.12rem;
                                    line-height: 0.4rem;
                                "
                                >暂无数据</span
                            > -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="health">
                <line-border-box type="bottom" />
                <item-title :name="'医疗养老'" english="Medical Pension" />
                <div class="health_box">
                    <div class="content_img_box">
                        <div
                            style="width: 100%"
                            v-for="(item, index) in healthList"
                            :key="index"
                        >
                            <serve-item @toggleOverlay="changeIfShow(item)"
                                :img="item.img"
                                :title="item.title"
                                :detailContent="item.detailContent"
                                :markerUrl="item.markerUrl"
                                :environmentalType="item.environmentalType"
                                :layerName="
                                    item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName
                                "
                                :ifshow="item.ifShow == 2 ? true : false"
                                :type="item.type"
                                :queryParameter="
                                    item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="lefe"
                style="
                    margin-top: 0.1rem;
                    padding-bottom: 0.08rem;
                    position: relative;
                "
            >
                <line-border-box type="bottom" />
                <item-title :name="'文化生活'" english="cultural life" />
                <div class="life_box">
                    <div class="content_img_box">
                        <div
                            style="width: 100%"
                            v-for="(item, index) in lifeList"
                            :key="index"
                        >
                            <serve-item @toggleOverlay="changeIfShow(item)"
                                :img="item.img"
                                :title="item.title"
                                :markerUrl="item.markerUrl"
                                :environmentalType="item.environmentalType"
                                :layerName="
                                    item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName
                                "
                                :ifshow="item.ifShow == 2 ? true : false"
                                :type="item.type"
                                :queryParameter="
                                    item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div v-show="menu_active != 1">
                <item-title :name="typeName" />
                <div class="content_img_box">
                    <div
                        v-for="(item, index) in listData"
                        style="width: 48%"
                        :key="index"
                        @click="changeIfShow(item)"
                    >
                        <common-layer-query-tool
                            :title="item.title"
                            :layerName="
                                item.type == 'addPoint' && !item.layerName
                                    ? 't03_xqd'
                                    : item.layerName
                            "
                            :ifshow="item.ifShow == 2 ? true : false"
                            :markerUrl="item.markerUrl"
                            :type="item.type"
                            :img="item.img"
                            :queryParameter="
                                item.queryParameter
                                    ? item.queryParameter
                                    : item.type == 'addPoint' && !item.layerName
                                    ? `mc='${item.title}'`
                                    : ''
                            "
                        />
                    </div>
                </div>
            </div> -->
        </div>
        <div class="page_temp page_temp_right">
            <div class="contentBox">
                <div class="sport">
                    <line-border-box type="bottom" />
                    <item-title name="体育运动" english="Spatial Planning" />
                    <div class="content_img_box" style="margin-left: 0.08rem">
                        <div
                            style="width: 100%"
                            v-for="(item, index) in sportList"
                            :key="index"
                            class="server-item"
                        >
                            <serve-item @toggleOverlay="changeIfShow(item)"
                                :img="item.img"
                                :title="item.title"
                                :placeArea="item.placeArea"
                                :markerUrl="item.markerUrl"
                                :environmentalType="item.environmentalType"
                                :layerName="
                                    item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName
                                "
                                :ifshow="item.ifShow == 2 ? true : false"
                                :type="item.type"
                                :queryParameter="
                                    item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''
                                "
                            />

                            <!-- <common-layer-query-tool
                                :title="item.title"
                                :layerName="
                                    item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName
                                "
                                :ifshow="item.ifShow == 2 ? true : false"
                                :markerUrl="item.markerUrl"
                                :type="item.type"
                                :img="item.img"
                                :queryParameter="
                                    item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''
                                "
                            /> -->
                        </div>
                    </div>
                </div>
                <div class="other">
                    <line-border-box type="bottom" />
                    <item-title name="其他配套" english="Village changes" />
                    <div class="content_img_box" style="margin-left: 0.08rem">
                        <div
                            style="width: 100%"
                            v-for="(item, index) in otherList"
                            :key="index"
                        >
                            <serve-item @toggleOverlay="changeIfShow(item)"
                                :img="item.img"
                                :title="item.title"
                                :detailContent="item.detailContent"
                                :markerUrl="item.markerUrl"
                                :environmentalType="item.environmentalType"
                                :layerName="
                                    item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName
                                "
                                :ifshow="item.ifShow == 2 ? true : false"
                                :type="item.type"
                                :queryParameter="
                                    item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 通用标注 -->
        <dot-pop v-for="(item, index) in v_marker_data" v-if="v_bln_show_marker" :key="index" type="dot" :x="item.x" :y="item.y" :z="1">
            <img style="width: 30px" :src="markerUrl" @click="showImg(item)"/>
		</dot-pop>

        <!-- 显示图片 -->
        <dot-pop :name="v_item.mc" v-show="v_if_show_img"  type="img" :x="v_item.x" :y="v_item.y" :z="1" :width="500" :height="400" @closePane="v_if_show_img=false">
			<img :src="v_item.img" style="height:370px" />
		</dot-pop>
    </div>
</template>

<script>
import itemTitle from '../components/item-title.vue'
import {
    industryOptions,
    machineryOption
} from '../components/farming/data.js'
import * as echarts from 'echarts';
import {
    mapState
} from 'vuex';
import commonLayerQueryTool from '@/components/commonLayerQueryTool.vue';
import {
    cleanMarkerListByType
} from '@/util/drawShape.js';
import lineBorderBox from "@/components/line-border-box.vue"
import serveItem from "@/components/serveItem";
import dotPop from "@/components/dot-pop.vue"
import {
    getServerData
} from '@/api/serve.js'
import {
    getDiShiZhouCodeByCunCode, getEnvelopeByList, getEnvelopeByPoints
} from '@/util/common.js';
import http from '@/util/http.js';
export default {
    components: {
        itemTitle,
        commonLayerQueryTool,
        dotPop,
        lineBorderBox,
        serveItem
    },
    data: function () {
        return {
            // 当前显示的类型
            layerName: '',
            // 农机服务
            nj_list_active: -1,
            v_nj_list: [],
            listData: [],
            healthList: [],
            lifeList: [],
            sportList: [],
            otherList: [],
            typeName: '村容村貌',
            dotColor: '#CFA972',
            dotIcon: require('../assets/marker/nongyongche.png'),
            
            g_xqid: localStorage.getItem('tenantCode'),
            g_dsz_id: getDiShiZhouCodeByCunCode(localStorage.getItem('tenantCode')),
            v_nj_list_state: true,
            //
            g_dsz_id: getDiShiZhouCodeByCunCode(localStorage.getItem('tenantCode')),
            v_list: [],
            v_new_list: []
            ,
            environmentalType: 0,
            img: '',
            title: '',
            markerUrl: require('@/assets/marker/location.png'),
            v_if_show_img: false,
            v_item: {x: 0, y: 0},
            v_bln_show_marker: false,
            v_marker_data: [],//自定义的标注数据集合
        }
    },
    computed: {
        ...mapState(['menu_active'])
    },
    watch: {
        menu_active: function (newValue, oldValue) {
            cleanMarkerListByType('aaa');
            this.getListData()

            this.nj_list_active = -1
            switch (newValue) {
                case 1:
                    this.farmMachinery();
                    break;
                case 2:
                    ;
                    break;
                case 3:
                    ;
                    break;
                case 4:
                    ;
                    break;
                case 5:
                    ;
                    break;
            }
        }
    },
    mounted() {
        let pMountedTimer = window.setInterval(() => {
            if (window.parentMounted) {
                window.clearInterval(pMountedTimer)
                this.init()
            }
        }, 500)

        this.$bus.$on('busEventShowMarker', (list, url)=>{
              this.v_marker_data = list;
            this.markerUrl = url;
            this.v_bln_show_marker = true;
        });
        this.$bus.$on('busEventHideMarker', ()=>{
            //console.log('bus->hide:');
        });
    },
    methods: {
        init: function () {
            this.farmMachinery()
            this.$parent.show2DMap();
            // 获取列表数据
            this.getListData()
            this.getList(4)
            this.getList(2)
            this.getList(3)
            this.getList(5)
            console.log('')
        },
        queryLayer() {
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "maxFeatures": 3000,
                "datasetNames": ["p" + this.g_dsz_id + ":t03_xqd"],
                "queryParameter": this.queryParameter ? {
                    "attributeFilter": "SSCID= '" + this.g_xqid + "' " + 'and ' + this.queryParameter,
                    "fields": ["smid"]
                } : {
                    "attributeFilter": "SSCID= '" + this.g_xqid + "' ",
                    "fields": ["smid", "mc", "sslb", "sscmc", "cjsj"]
                }
            }
            let x_y = [];
            http.requestIServerWithPage(_sqlParameter, 0, 2000, (result) => {
                // console.log('--回调--', result.totalCount, this.title);
                if (this.type == 'addPoint') {
                    if (result.features.length > 0) {
                        this.v_list = [];
                        for (var i = 0; i < result.features.length; i++) {
                            var _feature = result.features[i];
                            var _item = {
                                id: i,
                                smid: _feature.fieldValues[0],
                                name: _feature.fieldValues[1],
                                shape: [_feature.geometry.center.x, _feature.geometry.center.y]
                            };
                            this.v_list.push(_item);
                        }
                    }
                } else if (this.type == 'addLine') {
                    if (result.features.length > 0) {
                        for (var j = 0; j < result.features.length; j++) {
                            var dlx_point_jh = result.features[j].geometry.points;
                            x_y = [];
                            for (var i = 0; i < dlx_point_jh.length; i++) {
                                var every_point = dlx_point_jh[i];
                                x_y.push(every_point.x);
                                x_y.push(every_point.y);
                            }
                            this.v_list.push({
                                type: 1,
                                id: j + 1,
                                mc: result.features[j].fieldValues[0],
                                shape: x_y
                            });
                        }
                    }
                } else if (this.type == 'addPolygonFunction') {
                    if (result.features.length > 0) {
                        for (var i = 0; i < result.features.length; i++) {
                            var feature = result.features[i];
                            var dlx_point_jh = result.features[i].geometry.points;
                            x_y = []; //储存面图形的所有点
                            for (var j = 0; j < dlx_point_jh.length; j++) {
                                var every_point = dlx_point_jh[j];
                                x_y.push(every_point.x);
                                x_y.push(every_point.y);
                            }
                            this.v_list.push({
                                id: feature.fieldValues[0],
                                shape: x_y,
                                type: 2,
                                mc: feature.fieldValues[1],
                                szxq: feature.fieldValues[2],
                                cjsj: feature.fieldValues[3]
                            });
                        }
                    }

                } else if (this.type == 'drawCircle') {
                    for (var i = 0; i < result.features.length; i++) {
                        var feature = result.features[i];
                        var fsbj = 2000;
                        if (feature.fieldValues[2]) {
                            fsbj = parseFloat(feature.fieldValues[2]);
                        }
                        var item = {
                            id: feature.fieldValues[0],
                            mc: feature.fieldValues[1],
                            fsbj: fsbj, //feature.fieldValues[2],
                            type: 'jz',
                            x: feature.geometry.center.x,
                            y: feature.geometry.center.y,
                            isShowCenterPoint: true,
                            isShowCircle: true
                        }
                        this.v_list.push(item);
                    }
                }
            });
        },
        // 农机服务
        farmMachinery() {
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "maxFeatures": 3000,
                "datasetNames": ["p" + this.g_dsz_id + ":t03_fw_nj"],
                "queryParameter": {
                    "attributeFilter": "SSCID= '" + this.g_xqid + "' ",
                    "fields": ["smid", "mc", "sslb", "sscmc", "lxr", "lxdh"]
                }
            }
            http.requestIServerWithPage(_sqlParameter, 0, 2000, (result) => {
                if (result.features.length == 0) {
                    this.v_nj_list_state = false
                    return
                }
                this.v_nj_list_state = true
                let facilityTypeList = []
                let facilityData = []
                let facilityNumList = []
                result.features.forEach(item => {
                    if (facilityTypeList.indexOf(item.fieldValues[1]) == -1) {
                        facilityTypeList.push(item.fieldValues[1])
                        let obj = {}
                        obj.type = item.fieldValues[1]
                        obj.num = 1
                        obj.name = item.fieldValues[3]
                        obj.phone = item.fieldValues[4]
                        obj.shape = [
                            [item.geometry.points[0].x, item.geometry.points[0].y]
                        ]
                        facilityData.push(obj)
                    } else {
                        facilityData[facilityTypeList.indexOf(item.fieldValues[1])].num++
                        facilityData[facilityTypeList.indexOf(item.fieldValues[1])].shape.push([item
                            .geometry.points[0].x, item.geometry.points[0].y
                        ])
                    }
                })
                facilityData.forEach(item => {
                    facilityNumList.push(item.num)
                })
                // 农机设备列表
                this.v_nj_list = facilityData
                // 初始化柱状图
                let charts = echarts.init(document.getElementById('farmCharts'));
                let option = machineryOption
                option.xAxis.data = facilityTypeList
                option.series[0].data = facilityNumList
                option.grid.left = '8%'
                charts.setOption(option);
                // charts.on('click', params => {
                //     this.facilityClick(params.name)
                //     this.nj_list_active = -1
                // })
                window.addEventListener("resize", () => {
                    charts.resize();
                });
                let that = this
                charts.getZr().on('click', function (params) {
                    let pointInPixel = [params.offsetX, params.offsetY];
                    if (charts.containPixel('grid', pointInPixel)) {
                        let pointInGrid = charts.convertFromPixel({
                            seriesIndex: 0
                        }, pointInPixel);
                        let xIndex = pointInGrid[0]; 			// 索引
                        let handleIndex = Number(xIndex);
                        let name = option.xAxis.data[handleIndex]
                        that.facilityClick(name)
                        that.nj_list_active = -1
                        // that.cleanAllPolygon();
                        // that.requestNlmy(name,handleIndex);
                    }
                })
            })

        },
        // 农机服务点击事件
        facilityClick: function (name) {console.log('--name-', name);
            let that = this
            
            this.v_nj_list.forEach(item => {
                if (item.type == name) {
                    var _list = [];
                    item.shape.forEach(i => {
                        let json = {}
                        json.type = item.type
                        json.x = i[0];
                        json.y = i[1];
                        json.markerUrl = that.dotIcon;
                        json.environmentalType = 0;
                        _list.push(json);
                    })
                    this.v_marker_data = _list;
                    this.v_bln_show_marker = true;
                    this.markerUrl = this.dotIcon;
                }
            })
            var _envelope = getEnvelopeByPoints(that.v_marker_data);
            if (_envelope.length > 0) {
                viewer.camera.flyTo({
                    destination: Cesium.Rectangle.fromDegrees(
                        _envelope[0],
                        _envelope[1],
                        _envelope[2],
                        _envelope[3]
                    ) //west, south, east, north)
                });
            }
            //清除其他标注
            viewer.entities.removeAll();
            this.v_if_show_img = false;
        },
        getList(type) {
            getServerData(type).then(res => {
                if (type == 4) {
                    this.healthList = res.rows
                } else if (type == 2) {
                    this.lifeList = res.rows
                } else if (type == 3) {
                    this.sportList = res.rows
                } else if (type == 5) {
                    this.otherList = res.rows
                }
                // this.listData = res.rows
                cleanMarkerListByType('aaa');
            })
        },
        getListData: function () {
            this.listData = []
            let type = this.menu_active
            switch (type) {
                case 1:
                    this.typeName = '农机服务';
                    break;
                case 2:
                    this.typeName = '文化生活';
                    break;
                case 3:
                    this.typeName = '体育运动';
                    break;
                case 4:
                    this.typeName = '医疗养老';
                    break;
                case 5:
                    this.typeName = '其他配套';
                    break;
            }
            getServerData(type).then(res => {
                this.listData = res.rows
                cleanMarkerListByType('aaa');
            })
        },

        showImg(item){console.log('***', item.environmentalType, item, this.img);
             if(item.environmentalType != 4 && item.environmentalType != 5){
                 this.v_if_show_img = false;
                 return;
             }
             if(item.img){
                if(this.v_if_show_img){
                    this.v_if_show_img = false;
                }else{
                    this.v_item.x = item.x;
                    this.v_item.y = item.y;
                    this.v_item.mc = item.name;
                    this.v_item.img = item.img;
                    this.v_if_show_img = true;
                }
             }else{
                this.v_item.img = this.img;//默认的图片
             }
        },

        changeIfShow(item) {
            this.layerName = item.layerName
            viewer.entities.removeAll()
            let type = item.environmentalType
            this.v_new_list = []
            switch (type) {
                case 1:
                    this.v_new_list = this.appearanceList;
                    break;
                case 2:
                    this.v_new_list = this.lifeList;
                    break;
                case 3:
                    this.v_new_list = this.sportList;
                    break;
                case 4:
                    this.v_new_list = this.healthList;
                    break;
                case 5:
                    this.v_new_list = this.otherList;
                    break;
            }
            
            if(type != 2){this.lifeList.forEach(item => item.ifShow = 1);}
            if(type != 3){this.sportList.forEach(item => item.ifShow = 1);}
            if(type != 4){this.healthList.forEach(item => item.ifShow = 1);}
            if(type != 5){this.otherList.forEach(item => item.ifShow = 1);}
            this.v_new_list.forEach(i => {
                if (item != i) {
                    i.ifShow = 1;
                }
            })
            this.v_marker_data = [];
            this.v_bln_show_marker = false;
            this.v_if_show_img = false;
            item.ifShow == 1 ? item.ifShow = 2 : item.ifShow = 1;
        }
    },
    destroyed() {
        cleanMarkerListByType('aaa');
        this.v_marker_data = [];
        this.v_bln_show_marker = false;
        this.v_if_show_img = false;
    }
}
</script>

<style scoped lang="scss">
// .server-item:hover{
// 	background-color: #09fafa20;
// }
.title {
    height: 0.2rem;
    font-size: 0.14rem;
    font-weight: 500;
    color: #00ffff;
    line-height: 0.2rem;
    padding-left: 0.2rem;
    position: relative;
    margin-top: 0.08rem;

    &::before {
        content: '';
        width: 0.06rem;
        height: 0.06rem;
        background: #18f5f5;
        position: absolute;
        left: 0.03rem;
        top: 0.08rem;
    }

    &.col_f {
        color: #fff;
    }
}

.bottom_line {
    width: 3.9rem;
    height: 0.02rem;
    background: linear-gradient(
        90deg,
        rgba(24, 142, 245, 0) 0%,
        #188ef5 51%,
        rgba(24, 142, 245, 0) 100%
    );
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    position: relative;

    &::after {
        content: '';
        width: 12px;
        height: 12px;
        background: rgba(255, 255, 255, 0);
        background: #000;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.farmCharts {
    width: 100%;

    span {
        font-size: 0.12rem;
        color: rgba(255, 255, 255, 0.6);
        display: flex;
        justify-content: flex-start;
        margin-top: 0.05rem;
    }

    #farmCharts {
        width: 3.6rem;
        height: 1.4rem;
    }
}

// 分割线
.line_between {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 0.02rem;

    img {
        width: 100%;
        height: 100%;
    }

    margin: 0.2rem 0;
}

// 旋耕服务、收割服务分类、
.farm_classify {
    display: flex;
    justify-content: center;

    span {
        display: flex;
        width: 1.1rem;
        height: 0.34rem;
        background-color: transparent;
        border-radius: 0.04rem;
        border: solid 1px #1d80da;
        font-size: 0.14rem;
        justify-content: center;
        align-items: center;
        margin: 0 0.1rem;
        color: #c3e1ff;
        cursor: pointer;
    }

    .farm_classify_cur {
        background-color: #0894ff68;
        color: #fff;
    }
}

.cz {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 1.7rem;
    color: #fff;
    font-size: 0.14rem;

    .showBtn {
        width: 1.2rem;
        height: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        font-size: 0.15rem;
        background-image: linear-gradient(0deg, #1fd7fc 0%, #79b7f7 100%),
            linear-gradient(#1fd7fc, #1fd7fc);
        background-blend-mode: normal, normal;
        border-radius: 0.05rem;
        margin: 0.2rem 0.15rem 0 0;
        cursor: pointer;
    }

    .cz_list {
        width: 100%;
        display: flex;
        align-items: center;
        height: 0.3rem;
        line-height: 0.3rem;
        box-sizing: border-box;

        span {
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.14rem;
        }

        span:nth-child(2) {
            width: 10%;
        }
    }

    .cz_list_box {
        width: 100%;
        overflow: auto;
        justify-content: flex-start;
        flex: 1;
        padding: 0 0.11rem 0 0.15rem;
        box-sizing: border-box;

        .cz_list {
            cursor: pointer;
        }

        .cz_list:hover {
            color: #ffc748;
        }
    }

    .cz_list_box::-webkit-scrollbar {
        width: 0.04rem;
    }

    .cz_list_box::-webkit-scrollbar-thumb {
        background-color: #ddd;
        width: 0.04rem;
        border-radius: 0.05rem;
    }
}

.cz_list_active {
    color: #ffc748;
    background-color: #ffc74820;
}

.content_img_box {
    width: 98%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.1rem;
    box-sizing: border-box;

    .content_img_item {
        width: 1.63rem;
        height: 1.45rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0.2rem;
        cursor: pointer;

        div {
            width: 1.6rem;
            height: 1.2rem;
            background-color: #ffffff;
            box-shadow: 0px 0px 10px 1px rgba(228, 255, 254, 0.3);
            border-radius: 0.04rem;
            border: solid 1px #fff;
        }

        span {
            font-size: 0.14rem;
            color: #fff;
            line-height: 0.3rem;
        }
    }
}

.health {
    margin-top: 0.1rem;
    padding-bottom: 0.08rem;
    position: relative;
}

.health_box {
    .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-shrink: 0;
        width: 100%;
        margin-bottom: 0.08rem;

        .img {
            height: 0.73rem;
            width: 0.88rem;
            padding: 0.04rem;
            position: relative;
        }

        .content {
            // position: relative;
            margin-left: 0.08rem;

            .title {
                margin-top: 0;
            }

            .button {
                position: absolute;
                top: 0;
                right: 0;
                width: 0.5rem;
                height: 0.18rem;
                cursor: pointer;
            }

            p {
                font-size: 0.12rem;
                line-height: 0.18rem;
                color: #ebeff5;
                margin-top: 0.04rem;
                line-height: 0.18rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3; // 想要超出三行显示 就把这里改成3就好了
            }
        }
    }
}

.sport {
    // height: 4.2rem;
    position: relative;
    padding-bottom: 0.08rem;
    margin-bottom: 0.15rem;
}

// .item_bg > .img {
//     &::before {
//         content: '';
//         height: 100%;
//         width: 0.04rem;
//         background: url('~@/assets/img/server/img-bg-left.png') no-repeat;
//         background-size: 100% 100%;
//         position: absolute;
//         top: 0;
//         left: 0;
//     }
//     &::after {
//         content: '';
//         height: 100%;
//         width: 0.04rem;
//         background: url('~@/assets/img/server/img-bg-right.png') no-repeat;
//         background-size: 100% 100%;
//         position: absolute;
//         top: 0;
//         right: 0;
//     }
// }
.other {
    position: relative;
    padding-bottom: 0.38rem;
}
</style>
