module.exports = {
	// 产业布局柱状图配置
	industryOptions: {
		xAxis: {
			type: 'category',
			data: ['种植业', '林业', '畜牧业', '水产业'],
			axisLine: {
				lineStyle: {
					color: "#ddd"
				}
			},
			axisLabel: {
				color: "#fff",
				fontSize: ".12rem",
				formatter: function (value) {
					var ret = ""; //拼接加\n返回的类目项  
					var maxLength = 3; //每项显示文字个数  
					var valLength = value.length; //X轴类目项的文字个数  
					var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数  
					if (rowN > 1) //如果类目项的文字大于5,  
					{
						for (var i = 0; i < rowN; i++) {
							var temp = ""; //每次截取的字符串  
							var start = i * maxLength; //开始截取的位置  
							var end = start + maxLength; //结束截取的位置  
							//这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧  
							temp = value.substring(start, end) + "\n";
							ret += temp; //凭借最终的字符串  
						}
						return ret;
					} else {
						return value;
					}
				}
			}
		},
		grid: {
			top: "10%",
			left: "18%",
			right: "0",
			bottom: "20%"
		},
		tooltip: {
			trigger: 'axis',
			borderColor: "#092B46",
			backgroundColor: "#092B46",
			textStyle: {
			color: "#ffffff",
			fontSize: 14,
			align: 'left'
			},
			axisPointer: {
			type: 'shadow'
			},
			formatter: function(params) {
			return params[0].name + ': ' + params[0].value + '吨';
			}
		 },
		yAxis: {
			type: 'value',
			splitLine: { //网格线
				show: true,
				lineStyle: {
					// 使用深浅的间隔色
					color: "#ddd"
				}
			},
			axisLine: {
				lineStyle: {
					color: "rgba(225, 225, 225, 0.6)"
				}
			},
			axisLabel: {
				color: "#fff",
				fontSize: ".12rem",
				fontWeight: '300'
			}
		},
		series: [{
			data: [1500, 4500, 3200, 800],
			// type: 'bar',
			// 设置柱子颜色
			barWidth: 50,
			name: 'hill',
			type: 'pictorialBar',
			barCategoryGap: '40%',
			symbol: 'triangle',
			label: {
				show: false,
				position: 'top',
				distance: 5,
				color: '#F4FAFF',
				fontWeight: 'bolder',
				fontSize: 10
				},
			color: {
				type: 'linear',
				x: 0, //右
				y: 0, //下
				x2: 0, //左
				y2: 1, //上
				colorStops: [{
					offset: 0,
					color: '#21fffa' // 0% 处的颜色
				},
				{
					offset: 1,
					color: '#003cb2' // 100% 处的颜色
				}
				]
			},
			// 在柱子上面设置相对应的数字,
			// itemStyle: {
			//     normal: {
			//         label: {
			//             show: true,
			//             position: 'top',
			//             // 文字的颜色，字体大小，字体加深
			//             textStyle: {
			//                 color: '#19f7ff',
			//                 fontSize: '0.12rem',
			//                 fontWeight: 'bold'
			//             }
			//         },
			//         barBorderRadius: [6, 6, 0, 0]
			//     }
			// },

		}, {

		}]
	},
	// 土地用途
	landOptions: {
		chart: {
			backgroundColor: '#fff',
			type: 'pie',
			options3d: {
				enabled: true,
				alpha: 45,
				beta: 0
			}
		},
		title: {
			text: ''
		},
		tooltip: {
			color: '#fff',
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				depth: 35,
				dataLabels: {
					enabled: true,
					fontSize: '0.14rpx',
					format: '{point.name}'
				}
			}
		},
		series: [{
			type: 'pie',
			data: [{
				name: '水域',
				y: 11.9,
				color: '#3090d0'
			},
			{
				name: '林地',
				y: 26.8,
				color: '#65bec4'
			},
			{
				name: '旱地',
				y: 6.9,
				color: '#c5c963'
			},
			{
				name: '水田',
				y: 54.4,
				color: '#7dbf50'
			}
			]
		}]
	},
	// 产业布局数据
	industryTabData: [{
		name: '种植业',
		th: ["农作物", "种植面积（亩）", "产量（吨）"],
		td: []
	}, {
		name: '林业',
		th: ["树名", "种植面积（亩）", "产量（吨）"],
		td: []
	}, {
		name: '畜牧业',
		th: ["物种名", "养殖数量（只）", "产量（吨）"],
		td: []
	}, {
		name: '水产业',
		th: ["水产名", "养殖面积（亩）", "产量（吨）"],
		td: []
	}],
	// 农机统计
	machineryOption: {
		// backgroundColor: '#111c4e',
		color: ['#3398DB'],
		tooltip: {
			show: true,
			trigger: 'axis',
			axisPointer: {
				type: 'line',
				lineStyle: {
					opacity: 0,
				},
			},
			formatter: function (prams) {
				if (prams[0].name !== '') {
					return prams[0].name + ' : ' + prams[0].data;
				}
			},
		},
		grid: {
			left: '8%',
			top: '7%',
			right: '5%',
			bottom: '25%',
			// containLabel: true,
		},
		xAxis:
		{
			type: 'category',
			gridIndex: 0,
			data: ['种植业', '林业', '畜牧业', '水产业'],
			axisTick: {
				show: false,
			},
			axisLine: {
				show: false,
				lineStyle: {
					color: '#0c3b71',
				},
			},
			axisLabel: {
				color: "#fff",
				fontSize: ".12rem",
				formatter: function (value) {
					var ret = ""; //拼接加\n返回的类目项  
					var maxLength = 4; //每项显示文字个数  
					var valLength = value.length; //X轴类目项的文字个数  
					var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
					if (rowN > 1) //如果类目项的文字大于5,  
					{
						for (var i = 0; i < rowN; i++) {
							var temp = ""; //每次截取的字符串  
							var start = i * maxLength; //开始截取的位置  
							var end = start + maxLength; //结束截取的位置  
							//这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧  
							temp = value.substring(start, end) + "\n";
							ret += temp; //凭借最终的字符串  
						}
						return ret;
					} else {
						return value;
					}
				}
			},
		},
		yAxis:
		{
			type: 'value',
			// name:"单位:户",
			nameTextStyle: {
				color: 'rgb(170,170,170)',
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: 'rgba(255,255,255,0.2)', //网格线的颜色
					type: 'dashed',
				},
			},
			// min: min,
			// max: 100,
			axisLine: {
				show: false,
			},
			axisLabel: {
				color: 'rgb(170,170,170)',
				formatter: '{value}',
			},
		},
		series: [
			{
				// 分隔
				type: 'pictorialBar',
				itemStyle: {
					normal: {
						color: {
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [{
								offset: 0, color: '#009cff' // 0% 处的颜色
							}, {
								offset: 1, color: '#00e4ff'  // 100% 处的颜色17global
							}],
							global: false
						}
					},
				},
				symbolRepeat: 'fixed',
				symbolMargin: 2,
				symbol: 'rect',
				symbolClip: true,
				symbolSize: [20, 5],
				symbolPosition: 'start',
				symbolOffset: [0, -1],
				data: [1500, 4500, 3200, 800],
				z: 0,
				zlevel: 8,
			},
		],
	},
	// 地块表格数据
	landTabData: [{
		name: '流转记录',
		th: ["日期", "转出", "转入", "流转方式", "期限"],
		td: []
	}, {
		name: '种植信息',
		th: ["日期", "种植物"],
		td: []
	}, {
		name: '养护记录',
		th: ["日期", "养护项目", "养护人"],
		td: []
	}],
	wisdowData: [{
		name: '智慧种植',
		urls: [
			require('../../assets/img/farming/c3.png'),
			require('../../assets/img/farming/c3.png'),
			require('../../assets/img/farming/c3.png'),
		]
	}, {
		name: '智慧养殖',
		urls: [
			require('../../assets/img/farming/c3.png'),
			require('../../assets/img/farming/c3.png'),
			require('../../assets/img/farming/c3.png'),
		]
	}]
}
