<template>
    <div style="margin: 0">
        <div v-if="typeInfo == 1">
            <div class="clickContent">
                <div>
                    <span>{{ v_list.length || '0' }}</span
                    >个
                </div>
                <span>{{ title }}</span>
            </div>
        </div>
        <div v-else class="wrapper content_img_item">
            <div>
                <img
                    style="
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        -o-object-fit: cover;
                    "
                    :src="img"
                />
            </div>
            <span
                >{{ title
                }}{{ menu_active == 1 ? '' : '(' + v_list.length + ')' }}
            </span>
        </div>
        <div v-for="(item, index) in v_zu_list" :key="index" v-show="v_show_zu">
            <dot-pop
                :name="item.mc"
                :x="item.cx"
                :y="item.cy"
                :z="7"
                type="dot"
                :color="v_dot_color"
            >
                <img style="width: 0.3rem" :src="markerUrl" />
            </dot-pop>
        </div>
    </div>
</template>

<script>
import { getDiShiZhouCodeByCunCode } from '@/util/common.js';
import { getLonLatArray, addPoint, addPolygonFunction, addLine, cleanMarkerListByType, getMakerById, drawCircle } from '@/util/drawShape.js';
import http from '@/util/http.js';
import dotPop from "@/components/dot-pop.vue"
import {
    mapState
} from 'vuex';

var g_list_overlay = [];
var g_bln_height_up = '';//判断是否超过500，超过为true否则false
var g_zu_marker_list_overlay = [];
var g_HEIGHT_CHANG_CAMERA = 500;

export default {
    components: { dotPop },
    props: {
        title: String,
        layerName: String,
        type: String,
        queryParameter: String,
        img: String,
        typeInfo: String,
        dotPopActive: -1,
        layerType: { type: String, default: 'd' },//d x m  点线面
        fieldName: { type: String, default: 'mc' },
        markerUrl: { type: String, default: require('@/assets/marker/location.png') },
        width: { type: Number, default: 28 },
        height: { type: Number, default: 40 },
        ifshow: { type: Boolean, default: false }
    },
    data() {
        return {
            v_list: [],
            v_show: false,
            g_xqid: localStorage.getItem('tenantCode'),
            g_dsz_id: getDiShiZhouCodeByCunCode(localStorage.getItem('tenantCode')),
            v_dot_color: '#00c996',
            v_show_zu: false,
            v_zu_list: []//村小组
        }
    },
    watch: {
        ifshow: function (newVal, oldVal) {
            if (newVal) {
                this.show();
            } else {
                this.hide();
            }
        }
    },
    computed: {
        ...mapState(['menu_active'])
    },
    methods: {
        init() {
            this.queryLayer();
            if (this.layerName == 't04_hbss_ljt') {//只在查询垃圾桶时，再查小组信息
                this.requestCunXiaoZuList();
            }
        },

        listenHeight: function () {
            var height = viewer.camera.positionCartographic.height;
            var _height_up = height > g_HEIGHT_CHANG_CAMERA ? true : false;
            console.log('height----', parseInt(height));
            if (_height_up != g_bln_height_up) {
                this.handleMapZoom();
                //g_bln_height_up = _height_up;
            }
        },

        handleMapZoom: function () {
            var height = viewer.camera.positionCartographic.height;
            if (height > g_HEIGHT_CHANG_CAMERA) {//超过500米，显示小组定位，名称，数量
                console.log('heigth>' + g_HEIGHT_CHANG_CAMERA);
                //隐藏垃圾桶
                cleanMarkerListByType('layer');
                g_list_overlay = [];

                //显示组的垃圾桶数
                this.v_show_zu = true;

                /*    var _config = {};
                    _config.image = this.markerUrl;
                    _config.width = this.width;
                    _config.height = this.height;
                    _config.fillColor = '#00c996';
                    _config.backgroundColor = '#ffffff';
                    _config.offsetY = -20;
                    for (var i = 0; i < this.v_zu_list.length; i++) {
                        var _item = this.v_zu_list[i];
                        var _mc = _item.mc;// + '\n' + this.title + _item.count + '个';
                        g_zu_marker_list_overlay.push(addPoint('zu_marker_layer', _item.id, _mc, [_item.cx, _item.cy], '', _config));
                    }*/
                g_bln_height_up = true;
            } else {
                console.log('height=<' + g_HEIGHT_CHANG_CAMERA);
                //清除组的标注
                //cleanMarkerListByType('zu_marker_layer');
                //g_zu_marker_list_overlay = [];
                this.v_show_zu = false;

                //显示所有垃圾桶
                if (g_list_overlay.length == 0) {
                    var _config = {};
                    _config.image = this.markerUrl;
                    _config.width = this.width;
                    _config.height = this.height;
                    for (var i = 0; i < this.v_list.length; i++) {
                        var _item = this.v_list[i];
                        g_list_overlay.push(addPoint('layer', _item.id, '', _item.shape, '', _config));
                    }
                }
                g_bln_height_up = false;
            }
        },

        queryLayer() {
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "maxFeatures": 3000,
                "datasetNames": ["p" + this.g_dsz_id + ":" + this.layerName],
                "queryParameter": this.queryParameter ? {
                    "attributeFilter": "SSCID= '" + this.g_xqid + "' " + 'and ' + this.queryParameter,
                    "fields": ["smid"]
                } : {
                    "attributeFilter": "SSCID= '" + this.g_xqid + "' ",
                    "fields": ["smid", "mc", "sslb", "sscmc", "cjsj"]
                }
            }
            let x_y = [];
            http.requestIServerWithPage(_sqlParameter, 0, 2000, (result) => {
                console.log('--回调--', result.totalCount, this.title);
                if (this.type == 'addPoint') {
                    if (result.features.length > 0) {
                        this.v_list = [];
                        for (var i = 0; i < result.features.length; i++) {
                            var _feature = result.features[i];
                            var _item = {
                                id: i,
                                smid: _feature.fieldValues[0],
                                name: _feature.fieldValues[1],
                                shape: [_feature.geometry.center.x, _feature.geometry.center.y]
                            };
                            this.v_list.push(_item);
                        }
                    }
                } else if (this.type == 'addLine') {
                    if (result.features.length > 0) {
                        for (var j = 0; j < result.features.length; j++) {
                            var dlx_point_jh = result.features[j].geometry.points;
                            x_y = [];
                            for (var i = 0; i < dlx_point_jh.length; i++) {
                                var every_point = dlx_point_jh[i];
                                x_y.push(every_point.x);
                                x_y.push(every_point.y);
                            }
                            this.v_list.push({
                                type: 1,
                                id: j + 1,
                                mc: result.features[j].fieldValues[0],
                                shape: x_y
                            });
                        }
                    }
                } else if (this.type == 'addPolygonFunction') {
                    if (result.features.length > 0) {
                        for (var i = 0; i < result.features.length; i++) {
                            var feature = result.features[i];
                            var dlx_point_jh = result.features[i].geometry.points;
                            x_y = [];      //储存面图形的所有点
                            for (var j = 0; j < dlx_point_jh.length; j++) {
                                var every_point = dlx_point_jh[j];
                                x_y.push(every_point.x);
                                x_y.push(every_point.y);
                            }
                            this.v_list.push({
                                id: feature.fieldValues[0],
                                shape: x_y,
                                type: 2,
                                mc: feature.fieldValues[1],
                                szxq: feature.fieldValues[2],
                                cjsj: feature.fieldValues[3]
                            });
                        }
                    }

                } else if (this.type == 'drawCircle') {
                    for (var i = 0; i < result.features.length; i++) {
                        var feature = result.features[i];
                        var fsbj = 2000;
                        if (feature.fieldValues[2]) {
                            fsbj = parseFloat(feature.fieldValues[2]);
                        }
                        var item = {
                            id: feature.fieldValues[0],
                            mc: feature.fieldValues[1],
                            fsbj: fsbj, //feature.fieldValues[2],
                            type: 'jz',
                            x: feature.geometry.center.x,
                            y: feature.geometry.center.y,
                            isShowCenterPoint: true,
                            isShowCircle: true
                        }
                        this.v_list.push(item);
                    }
                }

            });
        },
        hide() {
            // cleanMarkerListByType('layer');
            // viewer.entities.removeAll()
            g_list_overlay = [];
            if (this.layerName == 't04_hbss_ljt') {//对垃圾桶进行特别处理
                viewer.camera.changed.removeEventListener(this.listenHeight);
                this.v_show_zu = false;
            }
        },
        show() {
            // cleanMarkerListByType('layer');
            if (this.type == 'addPoint') {
                var _config = {};
                _config.image = this.markerUrl;
                _config.width = this.width;
                _config.height = this.height;

                if (this.layerName == 't04_hbss_ljt') {//对垃圾桶进行特别处理
                    if (!this.checkDataFinish()) {
                        return;
                    }
                    //监听相机高度
                    viewer.camera.changed.addEventListener(this.listenHeight);
                    this.handleMapZoom();
                } else {
                    for (var i = 0; i < this.v_list.length; i++) {
                        var _item = this.v_list[i];
                        g_list_overlay.push(addPoint('layer', _item.id, '', _item.shape, '', _config));
                    }
                }
            } else if (this.type == 'addLine') {
                for (var i = 0; i < this.v_list.length; i++) {
                    var item = this.v_list[i];
                    if (this.layerName == "t04_xcdl") {
                        g_list_overlay.push(addLine('layer', item.id, '', item.shape, null, { lineColor: '#0084ff' }));
                    } else {
                        g_list_overlay.push(addLine('layer', item.id, '', item.shape, null, { lineColor: '#ffc748' }));
                    }
                }
            } else if (this.type == 'addPolygonFunction') {
                for (var i = 0; i < this.v_list.length; i++) {
                    var item = this.v_list[i];
                    var _config = { isFill: true };
                    if (this.layerName == "t02_slss_sk") {
                        _config.polygonFillColor = '#11abff';
                        _config.alpha = 0.7;
                    }
                    g_list_overlay.push(addPolygonFunction('layer', item.id, '', item.shape, null, _config));
                }
            } else if (this.type == 'drawCircle') {
                var _config = {};
                _config.image = this.markerUrl;
                _config.width = this.width;
                _config.height = this.height;
                for (var i = 0; i < this.v_list.length; i++) {
                    var item = this.v_list[i];
                    // g_list_overlay.push(drawCircle(this.layerName, item.x, item.y, item.fsbj));
                    g_list_overlay.push(addPoint('layer', item.id, '', [item.x, item.y], '', _config));
                }
            }
            var heading = Cesium.Math.toRadians(0.0);
            var pitch = Cesium.Math.toRadians(-90.0);
            viewer.flyTo(g_list_overlay, {
                //duration: 5,
                offset: new Cesium.HeadingPitchRange(heading, pitch)
            });
        },

        //用于检查小组的垃圾桶计数是否全部加载完，有一个没完成，返回false
        checkDataFinish: function () {
            for (var i = 0; i < this.v_zu_list.length; i++) {
                var _item = this.v_zu_list[i];
                if (!_item.finish) {
                    return false;
                }
            }
            return true;
        },

        requestCunXiaoZuList: function () {
            var _str_filter = "SSCID= '" + this.g_xqid + "' ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p" + this.g_dsz_id + ":t02_zu"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["id", "mc", "cx", "cy"],
                    "orderby": 'id'
                },
                "maxFeatures": 100
            }
            http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
                console.log('--村小组回调--', result.totalCount);
                if (result.features.length > 0) {
                    this.v_zu_list = [];
                    for (var i = 0; i < result.features.length; i++) {
                        var _feature = result.features[i];
                        var _lonLatArr = getLonLatArray(_feature.geometry.points);
                        this.v_zu_list.push({
                            id: i,
                            mc: _feature.fieldValues[1],
                            cx: parseFloat(_feature.fieldValues[2]), //_lonLatArr[0],
                            cy: parseFloat(_feature.fieldValues[3]), //_lonLatArr[1],
                            count: 0,
                            finish: false,
                            geometry: _feature.geometry,
                            shape: _lonLatArr
                        });
                        this.requestZuFeatureCount(i, _feature.geometry);
                    }

                }
            });
        },
        //执行包含查询
        requestZuFeatureCount: function (index, geometry) {
            //
            var _sqlParameter = {
                "getFeatureMode": "SPATIAL",
                "datasetNames": ["p" + this.g_dsz_id + ':' + this.layerName],
                'spatialQueryMode': "CONTAIN",
                "queryParameter": { fields: ["smid", "mc", "sscid"] },
                'hasGeometry': true,
                'geometry': geometry
            }
            http.requestIServerWithPage(_sqlParameter, 0, 1000, (result) => {
                //console.log('--包含查询回调--', this.layerName, result.totalCount);
                this.v_zu_list[index].count = result.featureCount;
                this.v_zu_list[index].mc = this.v_zu_list[index].mc + this.title + this.v_zu_list[index].count + '个';
                this.v_zu_list[index].finish = true;
            });
        }

    },
    created() { },
    mounted() {
        let pMountedTimer = window.setInterval(() => {
            if (window.parentMounted) {
                window.clearInterval(pMountedTimer)
                this.init();
            }
        }, 500)
    },
    destroyed() {
        cleanMarkerListByType('layer');
        cleanMarkerListByType('zu_marker_layer');
        this.v_show_zu = false;
        this.v_zu_list = [];
        g_list_overlay = [];
        if (this.layerName == 't04_hbss_ljt') {//对垃圾桶进行特别处理
            viewer.camera.changed.removeEventListener(this.listenHeight);
        }
    }
}
</script>
<style scoped lang="scss">
.wrapper {
    font-size: 12px;
    color: #555;
}

.content_img_item {
    width: 100%;
    height: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    div {
        width: 100%;
        height: 1.2rem;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 1px rgba(228, 255, 254, 0.3);
        border-radius: 0.04rem;
        border: solid 1px #fff;
        overflow: hidden;
    }

    div:hover {
        border: solid 1px #ffc748;
    }

    span {
        font-size: 0.14rem;
        color: #fff;
        line-height: 0.3rem;
    }
    .cls-selected {
        cursor: pointer;
    }
}

.clickContent {
    width: 1.26rem;
    height: 1.26rem;
    background-image: url(../assets/img/server/a2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 0.16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-left: 0.2rem;

    div {
        font-size: 0.14rem;
        color: #ffc748;

        span {
            font-size: 0.24rem;
            margin-right: 0.04rem;
        }
    }

    cursor: pointer;
}

.clickContent:hover {
    background-image: url(../assets/img/server/a1.png);
}

.clickContent > span {
    margin: 0.06rem 0;
}
</style>