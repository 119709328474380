var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page_temp page_temp_left"},[_c('div',{staticClass:"machinery",staticStyle:{"height":"4.3rem","position":"relative"}},[_c('div',[_c('item-title',{attrs:{"name":'农机服务',"english":"Agricultural machinery service"}}),(_vm.v_nj_list_state)?_c('div',[_c('line-border-box',{attrs:{"type":"bottom"}}),_c('div',{staticClass:"title",staticStyle:{"margin-top":"0.04rem"}},[_vm._v(" 农机统计 ")]),_vm._m(0),_c('div',{staticClass:"title",staticStyle:{"margin-top":"0.04rem"}},[_vm._v(" 农机信息 ")]),_c('div',{staticClass:"cz"},[_vm._m(1),_c('div',{staticClass:"cz_list_box"},_vm._l((_vm.v_nj_list),function(item,index){return _c('div',{key:index,staticClass:"cz_list",class:_vm.nj_list_active == index
                                            ? 'cz_list_active'
                                            : '',on:{"click":function($event){;(_vm.nj_list_active = index),
                                            _vm.facilityClick(item.type)}}},[_c('span',[_vm._v(_vm._s(item.type))]),_c('span',[_vm._v(_vm._s(item.num ||'--'))]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.name,"placement":"bottom-start"}},[_c('span',{staticStyle:{"display":"-webkit-box","-webkit-line-clamp":"1","overflow":"hidden","text-overflow":"ellipsis","-webkit-box-orient":"vertical","text-align":"center"}},[_vm._v(_vm._s(item.name ||'--'))])]),_c('span',[_vm._v(_vm._s(item.phone ||'--'))])],1)}),0)])],1):_c('div',[_vm._m(2)])],1)]),_c('div',{staticClass:"health"},[_c('line-border-box',{attrs:{"type":"bottom"}}),_c('item-title',{attrs:{"name":'医疗养老',"english":"Medical Pension"}}),_c('div',{staticClass:"health_box"},[_c('div',{staticClass:"content_img_box"},_vm._l((_vm.healthList),function(item,index){return _c('div',{key:index,staticStyle:{"width":"100%"}},[_c('serve-item',{attrs:{"img":item.img,"title":item.title,"detailContent":item.detailContent,"markerUrl":item.markerUrl,"environmentalType":item.environmentalType,"layerName":item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName,"ifshow":item.ifShow == 2 ? true : false,"type":item.type,"queryParameter":item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''},on:{"toggleOverlay":function($event){return _vm.changeIfShow(item)}}})],1)}),0)])],1),_c('div',{staticClass:"lefe",staticStyle:{"margin-top":"0.1rem","padding-bottom":"0.08rem","position":"relative"}},[_c('line-border-box',{attrs:{"type":"bottom"}}),_c('item-title',{attrs:{"name":'文化生活',"english":"cultural life"}}),_c('div',{staticClass:"life_box"},[_c('div',{staticClass:"content_img_box"},_vm._l((_vm.lifeList),function(item,index){return _c('div',{key:index,staticStyle:{"width":"100%"}},[_c('serve-item',{attrs:{"img":item.img,"title":item.title,"markerUrl":item.markerUrl,"environmentalType":item.environmentalType,"layerName":item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName,"ifshow":item.ifShow == 2 ? true : false,"type":item.type,"queryParameter":item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''},on:{"toggleOverlay":function($event){return _vm.changeIfShow(item)}}})],1)}),0)])],1)]),_c('div',{staticClass:"page_temp page_temp_right"},[_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"sport"},[_c('line-border-box',{attrs:{"type":"bottom"}}),_c('item-title',{attrs:{"name":"体育运动","english":"Spatial Planning"}}),_c('div',{staticClass:"content_img_box",staticStyle:{"margin-left":"0.08rem"}},_vm._l((_vm.sportList),function(item,index){return _c('div',{key:index,staticClass:"server-item",staticStyle:{"width":"100%"}},[_c('serve-item',{attrs:{"img":item.img,"title":item.title,"placeArea":item.placeArea,"markerUrl":item.markerUrl,"environmentalType":item.environmentalType,"layerName":item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName,"ifshow":item.ifShow == 2 ? true : false,"type":item.type,"queryParameter":item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''},on:{"toggleOverlay":function($event){return _vm.changeIfShow(item)}}})],1)}),0)],1),_c('div',{staticClass:"other"},[_c('line-border-box',{attrs:{"type":"bottom"}}),_c('item-title',{attrs:{"name":"其他配套","english":"Village changes"}}),_c('div',{staticClass:"content_img_box",staticStyle:{"margin-left":"0.08rem"}},_vm._l((_vm.otherList),function(item,index){return _c('div',{key:index,staticStyle:{"width":"100%"}},[_c('serve-item',{attrs:{"img":item.img,"title":item.title,"detailContent":item.detailContent,"markerUrl":item.markerUrl,"environmentalType":item.environmentalType,"layerName":item.type == 'addPoint' && !item.layerName
                                        ? 't03_xqd'
                                        : item.layerName,"ifshow":item.ifShow == 2 ? true : false,"type":item.type,"queryParameter":item.queryParameter
                                        ? item.queryParameter
                                        : item.type == 'addPoint' &&
                                          !item.layerName
                                        ? `mc='${item.title}'`
                                        : ''},on:{"toggleOverlay":function($event){return _vm.changeIfShow(item)}}})],1)}),0)],1)])]),_vm._l((_vm.v_marker_data),function(item,index){return (_vm.v_bln_show_marker)?_c('dot-pop',{key:index,attrs:{"type":"dot","x":item.x,"y":item.y,"z":1}},[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":_vm.markerUrl},on:{"click":function($event){return _vm.showImg(item)}}})]):_vm._e()}),_c('dot-pop',{directives:[{name:"show",rawName:"v-show",value:(_vm.v_if_show_img),expression:"v_if_show_img"}],attrs:{"name":_vm.v_item.mc,"type":"img","x":_vm.v_item.x,"y":_vm.v_item.y,"z":1,"width":500,"height":400},on:{"closePane":function($event){_vm.v_if_show_img=false}}},[_c('img',{staticStyle:{"height":"370px"},attrs:{"src":_vm.v_item.img}})])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"farmCharts"},[_c('span',[_vm._v("数量（台）")]),_c('div',{attrs:{"id":"farmCharts"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0 0.15rem","box-sizing":"border-box","width":"100%"}},[_c('div',{staticClass:"cz_list",staticStyle:{"background-color":"rgba(6, 65, 153, 0.6)","color":"rgba(255, 255, 255, 0.6)","margin-top":"0.08rem"}},[_c('span',[_vm._v("农机服务")]),_c('span',[_vm._v("数量")]),_c('span',[_vm._v("联系人")]),_c('span',[_vm._v("联系方式")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"center","align-items":"center","height":"3rem","flex-direction":"column"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/img/nodata.png"),"alt":""}})])
}]

export { render, staticRenderFns }